.shepherd-progress {
  font-weight: bold;
  margin-right: 10px;
}

.shepherd-title {
  flex: unset;
  line-height: 1.3em;
}

.shepherd-header {
  justify-content: space-between;
}

.shepherd-interceptor-overlay:hover {
  cursor: pointer;
}

.shepherd-interceptor-overlay {
  z-index: 1000;
}
